<template>
  <v-container
    fluid
    class="animate__animated animate__fadeInLeftBig px-md-8 px-lg-12"
  >
    <slot> </slot>
  </v-container>
</template>

<script>
export default {
  name: "mainframe",
};
</script>

<style></style>
